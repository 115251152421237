<style scoped lang="scss">
  .payment-form {
    position: relative;

    .loading-container {
      @include position-all(absolute, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(white, .5);
    }

    .payment-title {
      font-weight: 600;
      text-transform: uppercase;
    }

    .payment-methods {
      margin-top: 20px;
    }

    .payment-inputs {
      margin-top: 20px;

      .form-group + .form-group {
        margin-top: 20px;
      }

      .commission-section {
        margin-top: 20px;
        display: flex;
        align-items: center;
        user-select: none;

        .commission-checkbox {
          align-self: flex-start;
          flex-shrink: 0;
          flex-grow: 0;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--theme-color);
          background-color: rgba(var(--theme-color-rgb), .2);
          font-size: 20px;
          border-radius: 100%;
          cursor: pointer;

          &:hover {
            background-color: rgba(var(--theme-color-rgb), .3);
          }
        }

        .commission-text {
          margin-left: 12px;
          max-width: 470px;
        }
      }
    }

    .payment-total {
      margin-top: 30px;

      .total-text {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0;
      }

      .total-amounts {
        display: flex;
        align-items: center;
        gap: 20px;

        @include media(max, xs) {
          flex-direction: column;
          align-items: flex-start;
        }

        .total-amount {
          .total-amount-title {
            color: #1F1F1F;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            text-transform: uppercase;
          }

          .total-amount-text {
            margin-top: 7px;
            color: #191919;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 36px;
            letter-spacing: -0.5px;
          }
        }

        .total-amounts-divider {
          width: 1px;
          height: 43px;
          background: #E2E2E2;

          @include media(max, xs) {
            display: none;
          }
        }
      }

      .total-exchange-rate {
        margin-top: 16px;
        color: #838383;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.5px;
      }
    }

    .missing-amount {
      margin-top: 20px;
    }

    .payment-error {
      margin-top: 10px;
    }

    .payment-controls {
      margin-top: 20px;
    }

    .payment-legal-text {
      margin-top: 15px;
      font-size: 12px;
      color: #838383;
    }

    .phone-legal-documents {
      margin-top: 30px;
    }

    .success-message {
      margin-top: 15px;
    }
  }
</style>

<template>
  <div class="payment-form">
    <template v-if="successText">
      <div class="payment-title">{{ $t('payment.additionalActions') }}</div>

      <info-box class="success-message">
        {{ successText }}
      </info-box>
    </template>
    <template v-else>
      <div class="payment-title">{{ $t('payment.choosePaymentMethod') }}</div>

      <payment-methods />

      <div v-if="displayForm" class="payment-inputs">
        <form-group
          :state="!errors.email"
          :invalid-feedback="errors.email">
          <advanced-input
            v-model="viewerEmail"
            @input="onFieldInput('email')"
            icon-left="email"
            :placeholder="$t('payment.form.email')"
            @change="onEmailChange" />
        </form-group>

        <form-group
          v-if="displayQiwiWalletInput"
          :state="!errors.phone"
          :invalid-feedback="errors.phone">
          <advanced-input
            v-model="viewerQiwiWallet"
            @input="onFieldInput('qiwi')"
            icon-left="wallet"
            :placeholder="$t('payment.form.qiwiWallet')" />
        </form-group>

        <form-group
          v-if="displayPhoneInput"
          :state="!errors.phone"
          :invalid-feedback="errors.phone">
          <advanced-input
            v-model="viewerPhone"
            @input="onFieldInput('phone')"
            icon-left="phone"
            :placeholder="$t('payment.form.phone')" />
        </form-group>

        <form-group
          v-if="displayRegionSelect"
          :state="!errors.country"
          :invalid-feedback="errors.country">
          <payment-region-select />
        </form-group>

        <div v-if="commissionCoveringAllowed" class="commission-section">
          <div class="commission-checkbox" @click="toggleCommission">
            <icon :name="commissionCheckboxIcon" />
          </div>
          <div class="commission-text">
            {{ $t('payment.form.commissionCovered', { channel: creatorInfo.name }) }}
          </div>
        </div>
      </div>

      <info-box v-if="missingMethodAmount" class="missing-amount">
        <amount-link
          :template="methodAddUpAmountTemplate"
          :amount="missingMethodAmount"
          inline additive />
      </info-box>

      <template v-if="displayTotal">
        <div class="payment-total">
          <div class="total-amounts">
            <template v-if="totalAmountCurrency !== totalInitialAmountCurrency">
              <div class="total-amount">
                <div class="total-amount-title">{{ $t('payment.total.amount') }}</div>
                <div class="total-amount-text">{{ totalText.initial }}</div>
              </div>
              <div class="total-amounts-divider"></div>
            </template>
            <div class="total-amount">
              <div class="total-amount-title">{{ $t('payment.total.paymentAmount') }}</div>
              <div class="total-amount-text">{{ totalText.amount }}</div>
            </div>
          </div>
          <div v-if="totalAmountCurrency !== totalInitialAmountCurrency" class="total-exchange-rate">
            {{ totalText.exchangeRate }}
          </div>
        </div>

        <info-box v-if="errors.request" class="payment-error" variant="error">
          {{ errors.request }}
        </info-box>
      </template>

      <info-box v-if="error" class="payment-error" variant="error">
        {{ error }}
        <br><a href="/help/support" target="_blank">{{ $t('payment.form.contactSupport') }}</a>
      </info-box>

      <div v-if="paymentMethod" class="payment-controls">
        <btn
          v-if="displayDefaultPayButton"
          icon-left="gift"
          variant="primary"
          size="lg"
          :disabled="loaderVisible || !!missingMethodAmount"
          @click="processDefaultPay">{{ $t('global.common.send') }}
        </btn>
        <apple-pay-button
          v-if="displayApplePayButton"
          @click="processApplePay" />
        <google-pay-button
          v-if="displayGooglePayButton"
          @click="processGooglePay" />
      </div>
    </template>

    <div v-if="termsOfService.showCompanyDisclaimer" class="payment-legal-text">
      {{ $t('payment.legal') }}
    </div>

    <phone-legal-documents v-if="paymentMethod === 'fakeMobile'" />

    <div v-if="loaderVisible" class="loading-container">
      <loader size="lg" />
    </div>
  </div>
</template>

<script>

import applePay from '@services/apple-pay'
import googlePay from '@services/google-pay'

import PaymentMethods from './PaymentMethods'
import GooglePayButton from './GooglePayButton'
import ApplePayButton from './ApplePayButton'
import PaymentRegionSelect from './PaymentRegionSelect'
import PhoneLegalDocuments from './PhoneLegalDocuments'

import { INVOICE_TYPE } from '@state/modules/invoice'

export default {
  name: 'PaymentForm',
  components: {
    PhoneLegalDocuments,
    PaymentRegionSelect,
    ApplePayButton,
    GooglePayButton,
    PaymentMethods,
  },
  data() {
    return {
      loadingState: {
        finalAmount: false,
        invoice: false,
      },

      totalAmount: 0,
      totalAmountCurrency: 'RUB',
      totalInitialAmount: 0,
      totalInitialAmountCurrency: 'RUB',
      totalExchangeRate: 0,

      error: null,
      errors: {},

      successText: null,

      // Invoice
      pollingInvoice: false,
      pollingInvoiceTimeout: null,

      pollingAttemptsLeft: 40,
    }
  },
  computed: {
    ...mapState('creator', ['creatorInfo']),
    ...mapGetters('creator', ['getCreatorId']),
    ...mapGetters('currencies', ['getAmountWithCurrency']),

    ...mapState('donation', ['donationAmount']),

    ...mapState('payment', ['paymentMethod', 'commissionCoveringAllowed']),
    ...mapState('invoice', ['invoiceType', 'invoiceId', 'invoiceHash']),

    ...mapGetters('payment', ['selectedPaymentMethod', 'paymentRegionsList']),
    ...mapFields('payment', ['commissionCovered']),

    ...mapFields('viewer', ['viewerPhone', 'viewerEmail', 'viewerQiwiWallet']),

    ...mapState('creator', ['termsOfService']),

    loaderVisible() {
      return this.loadingState.finalAmount || this.loadingState.invoice
    },

    totalText() {
      return {
        amount: this.getAmountWithCurrency(this.totalAmount, this.totalAmountCurrency),
        initial: this.getAmountWithCurrency(this.totalInitialAmount, this.totalInitialAmountCurrency),
        exchangeRate: this.$t('payment.total.exchangeRate', {
          amountCurrency: this.totalAmountCurrency,
          exchangeRate: this.totalExchangeRate,
          initialCurrency: this.totalInitialAmountCurrency,
        }),
      }
    },

    commissionCheckboxIcon() {
      return this.commissionCovered ? 'heart' : 'heart-stroke'
    },

    displayForm() {
      return !!this.selectedPaymentMethod
    },

    displayRegionSelect() {
      return [
        'adyenSofortEur',
        'trustly',
      ].includes(this.paymentMethod) && !!this.paymentRegionsList.length
    },

    displayTotal() {
      return !!this.selectedPaymentMethod && !!this.totalAmount
    },

    displayPhoneInput() {
      return ['fakeMobile', 'fakeMobileDobro', 'sberPay'].includes(this.paymentMethod)
    },

    displayQiwiWalletInput() {
      return ['qiwiMyCom', 'qiwiDobro'].includes(this.paymentMethod)
    },

    displayApplePayButton() {
      return this.paymentMethod === 'applePay'
    },

    displayGooglePayButton() {
      return this.paymentMethod === 'googlePay'
    },

    displayDefaultPayButton() {
      return !this.displayApplePayButton && !this.displayGooglePayButton
    },

    missingMethodAmount() {
      if (!this.selectedPaymentMethod) {
        return 0
      }

      const missingAmount = this.selectedPaymentMethod.minAmount - this.donationAmount

      return missingAmount < 0 ? 0 : missingAmount
    },

    methodAddUpAmountTemplate() {
      return this.selectedPaymentMethod
        ? this.$t('payment.methodAddUpAmountTemplate', {
          method: this.selectedPaymentMethod.title,
          amount: '{amount}',
        })
        : null
    },
  },
  methods: {
    ...mapActions('invoice', ['createInvoice', 'createPartnerInvoice', 'checkInvoice']),
    ...mapActions('payment', ['fetchFinalAmount', 'fetchPaymentRegions']),

    onFieldInput(field) {
      this.$set(this.errors, field, null)
    },

    onEmailChange() {
      this.$tmr.goal('input_email_changed')
    },

    logPaymentProcess() {
      this.$ga.action({
        category: 'DonationPage-PaymentMethods',
        action: 'btn_checkout',
        label: this.paymentMethod,
      })

      this.$ga.action({
        category: 'AB_DonationPage',
        action: 'btn_checkout',
        label: this.paymentMethod,
      })

      this.$tmr.event({
        category: 'AB_DonationPage',
        action: 'new_action_btn_checkout',
        label: this.getCreatorId,
      })

      this.$tmr.goal('pay_clicked', {
        paymentSystem: this.paymentMethod,
      })
    },

    processGooglePay() {
      this.logPaymentProcess()

      this.loadingState.invoice = true

      this.error = null
      this.errors = {}

      googlePay.pay(this.totalAmountCurrency, this.totalAmount)
        .then(paymentToken => {
          this.processInvoice(paymentToken)
        })
        .catch(() => {
          console.log('[Google Pay] Cancelled')
        })
        .finally(() => {
          this.loadingState.invoice = false
        })
    },

    processApplePay() {
      this.logPaymentProcess()

      this.loadingState.invoice = true

      this.error = null
      this.errors = {}

      applePay.pay(this.totalAmountCurrency, this.totalAmount, this.creatorInfo.isDobro)
        .then(paymentToken => {
          this.processInvoice(paymentToken)
        })
        .catch(() => {
          console.log('[Apple Pay] Cancelled')
        })
        .finally(() => {
          this.loadingState.invoice = false
        })
    },

    processDefaultPay() {
      this.logPaymentProcess()

      this.processInvoice()
    },

    processInvoiceAction({ action, redirect_url, text }) {
      if (action === 'redirect') {
        window.location.href = redirect_url
      }

      if (action === 'wait') {
        this.startInvoicePolling()
      }

      if (action === 'close') {
        this.loadingState.invoice = false

        if (['googlePay', 'applePay'].includes(this.paymentMethod)) {
          return this.$router.replace({ name: 'payment-success-page' })
        }

        this.successText = text
      }
    },

    processInvoice(paymentToken = null) {
      this.loadingState.invoice = true

      this.error = null
      this.errors = {}

      const method = {
        [INVOICE_TYPE.DONATION]: this.createInvoice,
        [INVOICE_TYPE.PARTNER]: this.createPartnerInvoice,
      }[this.invoiceType]

      return method(paymentToken ? { paymentToken } : null)
        .then(({ invoice_id, action, redirect_url, text }) => {
          this.$tmr.event({
            category: 'AB_DonationPage',
            action: 'new_action_start_pay_process',
            label: invoice_id,
          })
          this.processInvoiceAction({ action, redirect_url, text })
        })
        .catch(({ message, errors }) => {
          this.loadingState.invoice = false

          if (message) {
            this.error = message
          }

          if (errors) {
            errors.forEach(e => {
              this.$set(this.errors, e.param, e.message)
            })
          }

          this.$tmr.goal('payment_failed', {
            paymentSystem: this.paymentMethod,
          })
        })
    },

    startInvoicePolling() {
      this.pollingAttemptsLeft = this.pollingAttemptsLeft - 1

      if (this.pollingAttemptsLeft <= 0) {
        this.loadingState.invoice = false

        this.$set(this.errors, 'request', this.$t('payment.form.timeoutError'))

        return
      }

      this.pollingInvoiceTimeout = setTimeout(() => {
        this.checkInvoice({ id: this.invoiceId, hash: this.invoiceHash })
          .then(({ action, redirect_url, text }) => {
            this.processInvoiceAction({ action, redirect_url, text })
          })
      }, 2000)
    },

    toggleCommission() {
      this.commissionCovered = !this.commissionCovered

      this.$ga.action({
        category: 'DonationPage-PaymentMethods',
        action: 'btn_heart',
      })

      this.$tmr.goal('donateComission_changed', {
        commission: ['streamer', 'viewer'][Number(this.commissionCovered)],
      })
    },

    calculateFinalAmount() {
      this.totalAmount = 0

      if (this.paymentMethod && !this.missingMethodAmount) {
        this.loadingState.finalAmount = true

        this.fetchFinalAmount()
          .then(({
            amount,
            currencyCode,
            initialAmount,
            initialCurrency,
            exchangeRate,
          }) => {
            this.totalAmount = amount
            this.totalAmountCurrency = currencyCode
            this.totalInitialAmount = initialAmount
            this.totalInitialAmountCurrency = initialCurrency
            this.totalExchangeRate = exchangeRate
          })
          .finally(() => {
            this.loadingState.finalAmount = false
          })
      }
    },

    async onPaymentMethodChange() {
      if (this.paymentMethod) {
        if (this.paymentMethod === 'adyenSofortEur' || this.paymentMethod === 'trustly') {
          this.loadingState.finalAmount = true
          await this.fetchPaymentRegions(['klarna'])
          this.loadingState.finalAmount = false
        }
      }

      return this.calculateFinalAmount()
    },
  },
  created() {
    this.calculateFinalAmount()

    this.$tmr.goal('checkout')
  },
  watch: {
    donationAmount: 'calculateFinalAmount',
    paymentMethod: {
      handler: 'onPaymentMethodChange',
      immediate: true,
    },
    commissionCovered: 'calculateFinalAmount',
  },
}
</script>
