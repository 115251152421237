import applePay from '@services/apple-pay'
import googlePay from '@services/google-pay'

import storage from '@utils/storage'
import cookies from '@utils/cookies'

const STORAGE_PAYMENT_SETTINGS = 'da.donation.paymentSettings'

const paymentMethodsIcons = {
  bankCardEur: 'payment-cards',
  bankCardRub: 'payment-cards',
  bankCardRubBank131: 'payment-cards',
  bankCardRubSmgl: 'payment-cards',
  bankCardUsd: 'payment-cards',
  bankCardYuno: 'payment-cards',
  applePay: 'payment-apple-pay',
  googlePay: 'payment-google-pay',
  boaCompraBankUsd: 'payment-br-bank',
  boaCompraMasterCardUsd: 'payment-mastercard',
  boaCompraCardsUsd: 'payment-br-card',
  boaCompraVisaUsd: 'payment-visa',
  fakeMobile: 'payment-sms',
  adyenSofortEur: 'payment-klarna',
  cryptocurrencies: 'payment-cryptocurrencies',
  sberPay: 'payment-sberpay',
  sberMyCom: 'payment-sberpay',
  vkPay: 'payment-vkpay',
  payPalEur: 'payment-paypal',
  payPalRub: 'payment-paypal',
  payPalUsd: 'payment-paypal',
  paySaferCardEur: 'payment-paysafe',
  paySaferCardUsd: 'payment-paysafe',
  qiwiMyCom: 'payment-qiwi',
  webMoneyUsd: 'payment-webmoney',
  webMoneyEur: 'payment-webmoney',
  yandexMoneyMyCom: 'payment-y-money',
  fasterPaymentsSystem: 'payment-faster-payments-system',
  fasterPaymentsSystemBank131: 'payment-faster-payments-system',
  fasterPaymentsSystemSmgl: 'payment-faster-payments-system',
  boletoFlashBrl: 'payment-boleto-flash',
  pix: 'payment-pix',
  trustly: 'payment-trustly',
  // Dobro
  bankCardRubDobro: 'payment-cards',
  fakeMobileDobro: 'payment-sms',
  qiwiDobro: 'payment-qiwi',
  sberDobro: 'payment-sberbank',
  webMoneyDobro: 'payment-webmoney',
  yandexMoneyDobro: 'payment-y-money',
}

export const state = {
  paymentMethods: {},

  paymentMethod: _.get(storage.getItem(STORAGE_PAYMENT_SETTINGS), 'paymentMethod', null),

  commissionCoveringAllowed: false,
  commissionCovered: _.get(storage.getItem(STORAGE_PAYMENT_SETTINGS), 'commissionCovered', false),

  paymentRegions: {},
  paymentRegion: _.get(storage.getItem(STORAGE_PAYMENT_SETTINGS), 'paymentRegion', false),

  paymentModalOpened: false,
}

export const getters = {
  getField,

  getPaymentMethod: state => code => state.paymentMethods[code],

  selectedPaymentMethod: (state, getters) => getters.getPaymentMethod(state.paymentMethod),

  paymentMethodsList: state => _.sortBy(Object.values(state.paymentMethods), 'order'),

  paymentRegionsList: state => Object.values(state.paymentRegions),
}

export const mutations = {
  updateField,

  SET_PAYMENT_METHODS(state, methods) {
    state.paymentMethods = _.keyBy(methods, 'code')
  },

  SET_PAYMENT_METHOD(state, method) {
    state.paymentMethod = method
  },

  SAVE_PAYMENT_SETTINGS(state) {
    storage.setItem(STORAGE_PAYMENT_SETTINGS, {
      paymentMethod: state.paymentMethod,
      commissionCovered: state.commissionCovered,
      paymentRegion: state.paymentRegion,
    })
  },

  SET_PAYMENT_REGIONS(state, regions) {
    state.paymentRegions = _.keyBy(regions, 'code')
  },

  SET_COMMISSION_COVERING_ALLOWED(state, value) {
    state.commissionCoveringAllowed = value
  },

  SET_PAYMENT_MODAL_OPENED(state, value) {
    state.paymentModalOpened = value
  },
}

export const actions = {
  init() {
    const apid = cookies.getItem('apid')

    if (!apid) {
      api.post('/anonymouspayer')
        .then(({ data }) => {
          cookies.setItem('apid', data.apid, Infinity, '/')
        })
    }
  },

  showPaymentModal({ commit }) {
    commit('SET_PAYMENT_MODAL_OPENED', true)
  },

  hidePaymentModal({ commit }) {
    commit('SET_PAYMENT_MODAL_OPENED', false)
  },

  fetchPaymentMethods({ commit, state, rootGetters, rootState }) {
    return api
      .get('/payin/systems', {
        user_id: rootGetters['creator/getCreatorId'],
        group: true,
        // amount: rootState['donation'].donationAmount,
        currency: rootState['currencies'].currentCurrency,
        apply_region_restrictions: true,
      })
      .then(({ data }) => {
        const restrictedMethods = []

        if (applePay.available) {
          if (_.map(data, 'key').includes('applePay')) {
            commit('SET_PAYMENT_METHOD', 'applePay')
          }
        } else {
          restrictedMethods.push('applePay')
        }

        if (!googlePay.available) {
          restrictedMethods.push('googlePay')
        }

        commit('SET_PAYMENT_METHODS', Object.values(data)
          .filter(method => !restrictedMethods.includes(method.key))
          .map((method, index) => ({
            code: method.key,
            title: method.title,
            group: method.group,
            order: {
              primary: 100,
              secondary: 200,
            }[method.group] + index,
            additional: method.addition,
            icon: paymentMethodsIcons[method.key],
            minAmount: method.min_amount_converted,
          })))

        if (!state.paymentMethods[state.paymentMethod]) {
          commit('SET_PAYMENT_METHOD', null)
        }
      })
  },

  fetchPaymentRegions({ commit }, types = []) {
    return api.get('/region', { types })
      .then(({ data }) => {
        return commit('SET_PAYMENT_REGIONS', data.map(region => ({
          code: region.alpha2,
          name: region.name,
        })))
      })
  },

  fetchFinalAmount({ state, rootGetters, rootState }) {
    const payload = {
      amount: rootState['donation'].donationAmount,
      currency: rootState['currencies'].currentCurrency,
      system: state.paymentMethod,
      commission_covered: state.commissionCovered,
      user_id: rootGetters['creator/getCreatorId'],
    }

    const {
      donationMessageType,
      speechSynthesisVoice,
      speechSynthesisEnabled,
    } = rootState['message'].message

    if (donationMessageType === 'text') {
      if (speechSynthesisEnabled) {
        const [voiceLocale, voiceId] = speechSynthesisVoice.split(':')

        payload.donation_tts_voice_id = voiceId === 'random' ? voiceId : Number(voiceId)
      }
    }

    return api
      .get('/payin/get_final_amount', payload)
      .then(({ data }) => ({
        amount: data.amount,
        currencyCode: data.currency_code,
        initialAmount: data.initial_amount,
        initialCurrency: data.initial_currency,
        exchangeRate: data.exchange_rate
      }))
  },
}
